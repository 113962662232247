const parallaxSections = document.querySelectorAll('.section-parallax');

if (parallaxSections && parallaxSections.length > 0) {
    const handleParallaxStyles = (section) => {
        const boundingRect = section.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        const percentage = Math.max(
            0,
            Math.min(
                100,
                ((viewportHeight - boundingRect.top) /
                    (viewportHeight + boundingRect.height)) *
                    100
            )
        );

        const backgroundDiv = section.querySelector(
            '.section-parallax__background'
        );
        const backgroundGradientBackground = section.querySelector(
            '.section-parallax__background-opacity'
        );
        const backgroundBlackBackground = section.querySelector(
            '.section-parallax__background-opacity-black'
        );

        const singlePersons = section.querySelectorAll(
            '.section-parallax__single-person'
        );

        backgroundDiv.style.backgroundPosition = `center ${parseFloat(
            percentage
        )}%`;

        if (percentage >= 20) {
            if (percentage <= 60) {
                backgroundGradientBackground.style.opacity = `${parseFloat(
                    percentage - 20
                )}%`;
            }

            if (percentage <= 53) {
                backgroundBlackBackground.style.opacity = `${parseFloat(
                    percentage - 20
                )}%`;
            }
        } else {
            backgroundGradientBackground.style.opacity = `0%`;
            backgroundBlackBackground.style.opacity = `0%`;
        }

        if (singlePersons) {
            singlePersons.forEach((person, i) => {
                person.classList.add('anim');
                if (percentage >= 45 && percentage <= 65) {
                    person.classList.add('active');
                } else if (percentage < 45) {
                    person.classList.remove('active');
                }
            });
        }
    }

    function handleButtonClick(section) {
        const button = section.querySelector(".js-trigger-form");
        const showHeaderForm = document.querySelector(".js-show-form");

        if (!button || !showHeaderForm) return;

        button.addEventListener("click", () => {
            showHeaderForm.click();
        })
    }

    parallaxSections.forEach(section => {
        window.addEventListener('scroll', () => { 
            handleParallaxStyles(section);
        })
        handleParallaxStyles(section);
        handleButtonClick(section);
    });
}
